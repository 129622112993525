.gsui-s3select {
  position: relative;
  display: flex;
  justify-content: space-between;
  .s3-input {
    width: 80%;
    input {
      padding: 4px 10px 4px 10px;
      border-radius: 2px;
      border: 1px solid #aab7b8;
      background-color: #fff;
      height: 32px;
      line-height: 32px;
      width: 100%;
    }
  }
  button {
    margin-top: -2px;
  }
}
