$select-color: #0073bb;
$input-max-width: 50px;
.gsui-table-pannel {
  background-color: #fff;
  padding-top: 10px;
  .desc {
    padding: 0 20px;
    color: #666;
    font-size: 14px;
    margin-top: -10px;
  }
  .table-header {
    width: 100%;
    display: flex;
    padding: 10px 20px 10px 20px;
    justify-content: space-between;
    .title {
      flex: 1;
      font-size: $font-size-18;
      font-weight: 700;
    }
    .action {
      // flex: 1;
      text-align: right;
      button {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .filter {
      flex: 1;
      max-width: 700px;
    }
  }
  .gsui-table {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      th,
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      thead {
        tr {
          th {
            background-color: #fafafa;
            border-top: 1px solid #eaeded;
            border-bottom: 1px solid #eaeded;
            position: relative;
            // border-right: 1px solid #eaeded;
            min-width: 100px;
            padding: 9px 0px;
            .content {
              padding: 0 10px;
              border-right: 1px solid #eaeded;
            }
          }
        }
      }
      tbody {
        tr {
          tab-size: 8;
          text-align: left;
          td {
            word-wrap: break-word;
            border-top: 1px solid transparent;
            border-bottom: 1px solid #eaeded;
            min-width: 100px;
            padding: 9px 10px;
          }
          &.selected {
            border: 1px solid $select-color;
            background-color: #f1faff;
            td {
              border-top: 1px solid $select-color;
              border-bottom: 1px solid $select-color;
              &:first-child {
                border-left: 1px solid $select-color;
              }
              &:last-child {
                border-right: 1px solid $select-color;
              }
            }
          }
        }
      }
    }
    .body-cell-input {
      table-layout: fixed;
      max-width: $input-max-width;
      min-width: $input-max-width;
      width: $input-max-width;
      padding: 12px 5px 7px;
      text-align: center;
    }
  }
  .table-loading {
    text-align: center;
    padding: 20px;
  }
  .table-empty {
    color: #555;
    padding: 20px;
    text-align: center;
  }
}
